import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HelperService } from '../../shared/services/helper.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { DocumentsType, BioMatchRequest, BioMatchResponse, Parameter, ParameterName, DataParameter } from '../../models/parameter';
import { TokenRipley } from '../../models/integration';

@Injectable({
  providedIn: 'root'
})
export class ParameterService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = "Parameter";
  private BASE_URL: string = HelperService.buildRequestURL(environment.msBackofficeApi);
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  getDocuments(): Observable<DocumentsType[]> {
    return this.httpClient.get<DocumentsType[]>(`${this.REQUEST_URL}/getDocumentsType`);
  }

  getTokenRipley(): Observable<TokenRipley> {
    return this.httpClient.get<TokenRipley>(`${this.REQUEST_URL}/getTokenRipley`);
  }

  postValidationBiometric(request: BioMatchRequest) {
    return this.httpClient.post<BioMatchResponse>(`${this.REQUEST_URL}/Autentication/BioMatch`, request);
  }

  getParameters(code : number): Observable<Parameter[]> {
    return this.httpClient.get<Parameter[]>(`${this.REQUEST_URL}/${code}`);
  }

  createParameter(parameter: DataParameter): Observable<DataParameter> {
      return this.httpClient.post<DataParameter>(`${this.REQUEST_URL}/CreateParameter`, parameter);
  }

  updateParameter(parameter: Parameter): Observable<Parameter> {
        return this.httpClient.put<Parameter>(`${this.REQUEST_URL}/UpdateParameter`, parameter);
    }

  getNameParameter(parameters: any) {
        let httpParams = new HttpParams({
            fromObject: parameters
        });
      return this.httpClient.get<ParameterName[]>(`${this.REQUEST_URL}/GetNameParameter`, { params: httpParams });
    }

  getParametersDetail(codeParameter: number): Observable<Parameter[]> {
      return this.httpClient.get<Parameter[]>(`${this.REQUEST_URL}/ffff/${codeParameter}`);
  }

  getMenu() {
    return this.httpClient.get<any[]>(`${this.REQUEST_URL}/GetMenu`);
  }

  getAgency() {
    return this.httpClient.get<any>(`${this.REQUEST_URL}/GetAgency`);
  }

  getParametersList(request : any): Observable<Parameter[]> {
    return this.httpClient.post<Parameter[]>(`${this.REQUEST_URL}/GetParametersList`, request);
  }
  getAllParametersList(request : any): Observable<Parameter[]> {
    return this.httpClient.post<Parameter[]>(`${this.REQUEST_URL}/GetAllParametersList`, request);
  }
  getVC(code : string): Observable<Parameter> {
    return this.httpClient.get<Parameter>(`${this.REQUEST_URL}/GetVC/${code}`);
  }

}
