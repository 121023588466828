import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PinpadConnectService } from '../../services/pinpad-connect.service';
import { SolicitudService } from '../../../services/solicitud.service';
import { RequestValidatePinpadTicket, ResponseValidate } from '../../../models/solicitud';
import { Parameter } from '../../../models/parameter';
import { HttpErrorResponse } from '@angular/common/http';
import { ParameterService } from '../../../services/parameter.service';
import { AppConstants, PINPAD_ERROR_CODE, PINPAD_VALIDATE_CODE } from '../../constants/app.constants';
import { NotificationService } from '../../../services/notification.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { PinpadConnectServiceTd } from '../../services/pinpad-connect-td.service';

@Component({
  selector: 'onb-pinpad-button-td',
  templateUrl: './onb-pinpad-button-td.component.html',
  styleUrls: ['./onb-pinpad-button-td.component.scss']
})
export class OnbPinpadButtonTdComponent implements OnInit {

  loading: boolean = false;
  @Input() parameters: string;
  @Input() tipoSol: string;
  @Output() ConnectPINPAD = new EventEmitter<any>();
  ticketResult: any;
  byPassPinPad: boolean = false;

  constructor(
    private pinpadConnectService: PinpadConnectServiceTd,
    private parameterService: ParameterService,
    private notificationService: NotificationService,
    private alertaService: AlertService,) {

    this.GetParameters([
      AppConstants.ParameterCode.ADICIONAL_PARAMETERS
    ]);
  }
  ngOnInit(): void {
  }

  async GetParameters(array: Array<number>) {
    let modelRequest = { headerId: array };
    this.loading = true;

    // registrar movimiento
    await this.parameterService.getParametersList(modelRequest).toPromise().then((response) => {
      this.setVariablesParameters(response);
      this.loading = false;
    },
      (error: HttpErrorResponse) => {
        this.loading = false;
      });
  }

  setVariablesParameters(listParameter: Parameter[]) {
    this.byPassPinPad = listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.ADICIONAL_PARAMETERS
      && f.tabDetState == AppConstants.StateFlag.Activo
      && f.tabDetCode001 == "15"
      && f.tabDetCode006 == "SI").length > 0 ? true : false;
  }

  async OnConnectPINPAD() {
    this.loading = true;
      
    if (this.byPassPinPad) {
      let result: ResponseValidate = new ResponseValidate();
      result.isValid = true;
      result.resultCode = "";
      this.ConnectPINPAD.emit(result);
      this.loading = false;
      return;
    }

    let responsePinPad = await this.pinpadConnectService.ConnectPINPAD(this.tipoSol);
    if(responsePinPad != null){
      if(!this.isValidJSON(responsePinPad)){
        this.ConnectPINPAD.emit(this.setResultError(PINPAD_ERROR_CODE.ERROR_VALIDATE_SERVICE_TICKET));
        this.loading = false;
      }
      let jsonResponse = JSON.parse(responsePinPad);
      if(jsonResponse!=null && jsonResponse.status.statusCode == "00" && jsonResponse.responseFields.response_code == "00"){
        let responseValidate = new ResponseValidate();
        responseValidate.isValid = true;
        responseValidate.message = 'TRANSACCION EXITOSA';
        responseValidate.resultCode = '00';
        this.ConnectPINPAD.emit(responseValidate);
        this.loading = false;
      }else{
        let responseValidate2 = new ResponseValidate();
        responseValidate2.isValid = false;
        responseValidate2.message = 'ERROR - PIN';
        responseValidate2.resultCode = jsonResponse.responseFields.response_code;
        this.ConnectPINPAD.emit(responseValidate2);
        this.loading = false;
      }
    }
    else{
      this.ConnectPINPAD.emit(this.setResultError(PINPAD_ERROR_CODE.ERROR_HARDWARE));
      this.loading = false;
    }
    
  }

  setResultEmpty(): ResponseValidate {
    let result: ResponseValidate = new ResponseValidate();
    result.isValid = false;
    result.resultCode = null;
    return result;
  }

  setResultError(code: string): ResponseValidate {
    let result: ResponseValidate = new ResponseValidate();
    result.isValid = false;
    result.resultCode = code;
    return result;
  }

  isValidJSON(input: string): boolean {
    try {
        JSON.parse(input); // Intentar parsear
        return true;       // Es un JSON válido
    } catch {
        return false;      // No es un JSON válido
    }
}
}
