import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'solicitud-success',
  templateUrl: './solicitud-success.component.html',
  styleUrls: ['./solicitud-success.component.scss'],
})
export class SolicitudSuccessComponent implements OnInit {
  @Output() return = new EventEmitter();
  constructor() { }

  ngOnInit() {
    sessionStorage.removeItem('dataServAsoc');
    sessionStorage.removeItem('CLIENTE');
    sessionStorage.removeItem('PRODUCTO');
  }

  onReturn() {
    this.return.emit();
  }

}
